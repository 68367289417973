import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, take, tap } from 'rxjs';
import { Code } from 'src/app/core/models/code';
import { CommonService } from 'src/app/core/services/api/common.service';
import { SchoolSportService } from 'src/app/core/services/api/school-sport.service';
import { SchoolSport, Sport } from '../../../models/school';
import { SportService } from '../../../services/api/sport.service';
import { UserService } from '../../../services/api/user.service';
import { handleUniqueValidator } from 'src/app/core/validators/handle-unique.validator';
import { HandleService } from 'src/app/core/services/api/handle.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LicenseStateService } from 'src/app/core/services/state/license-state.service';
import { PandaTemplateStateService } from 'src/app/core/services/state/panda-template-state.service';

@UntilDestroy()
@Component({
  selector: 'app-school-sport-update-create',
  templateUrl: './school-sport-update-create.component.html',
  styleUrls: ['./school-sport-update-create.component.scss']
})
export class SchoolSportUpdateCreateComponent implements OnInit {

  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  saveError = false;
  sportTypes: Code[] = [];
  sports: Sport[];
  schoolId: number;
  saving = false;
  savingHandle = false;
  schoolHandle: string = "";
  updateHandle = false;

  handleControl = new FormControl('');

  licenses$ = this.licenseStateService.licenses$;
  templates$ = this.pandaTemplateStateService.templates$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: SchoolSport,
    private sportService: SportService,
    private dialogRef: MatDialogRef<SchoolSportUpdateCreateComponent>,
    private fb: UntypedFormBuilder,
    private schoolSportService: SchoolSportService,
    public commonService: CommonService,
    public userService: UserService,
    private handleService: HandleService,
    private licenseStateService: LicenseStateService,
    private pandaTemplateStateService: PandaTemplateStateService
  ) {
    this.userService.user.pipe(take(1)).subscribe(user => {
      this.schoolId = user.school.id;
      this.schoolHandle = user.school.handleString;
      if (this.userService.isAdmin()) {
        this.licenseStateService.init(user.schoolId);
        this.pandaTemplateStateService.init();
      }
    })
    this.getContextData();
  }

  ngOnInit(): void {

    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as SchoolSport;
    }

    this.form = this.fb.group({
      id: this.defaults.id || null,
      gender: [this.defaults.gender || null, Validators.required],
      nickname: [this.defaults.nickname || null, Validators.required],
      sport: [this.defaults.sport?.id || null, Validators.required],
      genderDisplayName: [this.defaults.genderDisplayName || null],
      school: [this.schoolId, Validators.required],
      useNicknameInStore: [this.defaults.useNicknameInStore || false],
      useNicknameOnProducts: [this.defaults.useNicknameOnProducts || false],
      noStore: [this.defaults.noStore || false],
      noRoster: [this.defaults.noRoster || false],
      handleString: [this.defaults.handleString || null],
      license: [this.defaults.license || null],
      requiredPlayerTemplate: [this.defaults.requiredPlayerTemplate || null]
    });

    this.handleControl.patchValue(this.defaults.handleString.replace(`${this.schoolHandle}-`, ""));
    this.handleControl.addAsyncValidators(handleUniqueValidator(this.defaults.handleString, this.handleService, `${this.schoolHandle}-`))
    this.handleControl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => {
      this.form.patchValue({ handleString: `${this.schoolHandle}-${value}` });
    })
  }

  getContextData(): void {
    this.sportService.getAll().pipe(
      take(1),
      map(sports => {
        return sports.sort((a, b) => a.name.localeCompare(b.name))
      })
    ).subscribe(sports => {
      this.sports = sports;
    })
  }

  save() {
    if (this.mode === 'create') {
      this.create();
    } else if (this.mode === 'update') {
      this.update();
    }
  }

  create() {
    const schoolSport: SchoolSport = this.form.value;
    schoolSport.nickname = schoolSport.nickname ? schoolSport.nickname.toUpperCase() : schoolSport.nickname;
    schoolSport.genderDisplayName = schoolSport.genderDisplayName ? schoolSport.genderDisplayName.toUpperCase() : schoolSport.genderDisplayName;
    this.saving = true;
    this.schoolSportService.create(schoolSport).subscribe(createdSchoolSport => {
      if (createdSchoolSport) {
        this.dialogRef.close(createdSchoolSport);
      } else {
        this.saveError = true;
      }
      this.saving = false;
    })
  }

  update() {
    const schoolSport: SchoolSport = this.form.value;
    schoolSport.nickname = schoolSport.nickname ? schoolSport.nickname.toUpperCase() : schoolSport.nickname;
    schoolSport.genderDisplayName = schoolSport.genderDisplayName ? schoolSport.genderDisplayName.toUpperCase() : schoolSport.genderDisplayName;
    this.saving = true;
    this.schoolSportService.edit(schoolSport).subscribe(updatedSchoolSport => {
      if (updatedSchoolSport) {
        this.dialogRef.close(updatedSchoolSport);
      } else {
        this.saveError = true;
      }
      this.saving = false;
    })
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  saveHandle(handle: string) {
    const schoolSport: SchoolSport = {
      id: this.form.value.id,
      handleString: `${this.schoolHandle}-${handle}`
    };
    this.savingHandle = true;
    this.schoolSportService.partialEdit(schoolSport).subscribe(updatedSchoolSport => {
      this.savingHandle = false;
      this.updateHandle = false;
      this.defaults.handleString = updatedSchoolSport.handleString;
      this.form.patchValue({ handleString: updatedSchoolSport.handleString });
    });
  }
}
