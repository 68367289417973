import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BestSelling } from '../../models/home';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class OrgHomeService {
  orgBestSelling$:BestSelling[]=null
  constructor(private httpHelper: HttpHelperService) { }
  resetState(){
    this.orgBestSelling$=null;
  }
  orgBestSelling(orgId:number):Observable<BestSelling[]>{
    return this.httpHelper.request<BestSelling[]>(`organization/${orgId}/best_selling`, { method: 'GET'});

  }
}
