import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SchoolSport } from '../../models/school';
import { VerificationEmailResponse } from '../../models/utils';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolSportService {
  allSchoolSportsList$ : SchoolSport[]=null;
  private endpoint: string = 'school-sports';

  constructor(private httpHelper: HttpHelperService) { }
  resetState(){
    this.allSchoolSportsList$=null;
  }
  getAll(filter?: any): Observable<SchoolSport[]> {
    return this.httpHelper.request<SchoolSport[]>(this.endpoint, { search: filter });
  }

  get(id: number, filter?: any): Observable<SchoolSport> {
    return this.httpHelper.request<SchoolSport>(`${this.endpoint}/${id}`, { search: filter });
  }

  filter(item: SchoolSport): Observable<SchoolSport[]> {
    return this.httpHelper.request<SchoolSport[]>(`${this.endpoint}`, { params: item, method: 'GET' });
  }

  create(item: SchoolSport): Observable<SchoolSport> {
    return this.httpHelper.request<SchoolSport>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: SchoolSport): Observable<SchoolSport> {
    return this.httpHelper.request<SchoolSport>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  partialEdit(item: Partial<SchoolSport>): Observable<SchoolSport> {
    return this.httpHelper.request<SchoolSport>(`${this.endpoint}/${item.id}`, { body: item, method: 'PATCH' });
  }

  delete(id: number): Observable<SchoolSport> {
    return this.httpHelper.request<SchoolSport>(`${this.endpoint}/${id}`, { method: 'DELETE' });
  }

  exportCSV(sport) {
    const exportCSVParams = new HttpParams({ fromObject: { 'file_format': 'csv' } })
    return this.httpHelper.request<void>(`${this.endpoint}/${sport.sport.id}/export_players`, { method: 'GET', search: exportCSVParams, responseType: 'blob' });
  }

  exportXLSX(sport) {
    const exportXLSXParams = new HttpParams({ fromObject: { 'file_format': 'xlsx' } })
    return this.httpHelper.request<void>(`${this.endpoint}/${sport.sport.id}/export_players`, { method: 'GET', search: exportXLSXParams, responseType: 'blob' });
  }

  importCSV(sport, uploadedFile) {
    return this.httpHelper.request<void>(`${this.endpoint}/${sport.sport.id}/import_players`, { body: uploadedFile, method: 'POST' });
  }
  importXLSX(sport, uploadedFile) {
    return this.httpHelper.request<void>(`${this.endpoint}/${sport.sport.id}/import_players`, { body: uploadedFile, method: 'POST' });
  }

  sendSignUpEmails(sport): Observable<VerificationEmailResponse> {
    return this.httpHelper.request<VerificationEmailResponse>(`${this.endpoint}/${sport.id}/send_signup_emails`, { method: 'GET' });
  }
}
