import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JournalEntrySale, PoolLicenseSettings } from '../../models/sales';
import { PLAYERYEARS, Player, PlayerDocument, PlayerPaginate, PlayerVideoData } from '../../models/school';
import { HttpHelperService } from '../http-helper.service';
import { PaginatedData, PaginatedRequest } from '../../models/utils';

export type AthleteSignUpResponse = {
  total: number; 
  newThisWeek: number; 
  newThisMonth: number;
}

@Injectable({
  providedIn: 'root',
})
export class PlayersService {
  playerSports$ : Player[]=null;
  playerList$ : Player[]=null;
  private endpoint: string = 'players';

  constructor(private httpHelper: HttpHelperService) {}

  resetState(){
    this.playerList$ = this.playerSports$=null;
  }

  getAll(filter?: any): Observable<Player[]> {
    return this.httpHelper.request<Player[]>(this.endpoint, { search: filter });
  }

  getPaginatedPlayers(request: PaginatedRequest<Player>): Observable<PaginatedData<Player>> {
    return this.httpHelper.request<PaginatedData<Player>>(this.endpoint, { method: 'GET', search: request });
  }

  get(id: number): Observable<Player> {
    return this.httpHelper.request<Player>(`${this.endpoint}/${id}`);
  }

  create(playerData: Player): Observable<Player> {
    return this.httpHelper.request<Player>(`${this.endpoint}`, {
      body: playerData,
      method: 'POST',
    });
  }

  edit(playerData: Player): Observable<Player> {
    return this.httpHelper.request<Player>(`${this.endpoint}/${playerData.id}`, {
      body: playerData,
      method: 'PUT',
    });
  }

  partialEdit(playerData: Player): Observable<Player> {
    return this.httpHelper.request<Player>(`${this.endpoint}/${playerData.id}`, {
      body: playerData,
      method: 'PATCH',
    });
  }

  updateBuildAts(ids: number[]): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/update_build_ats`, { body: {ids}, method: 'POST' });
  }

  deletePlayer(playerData:Player):Observable<Player>{
    return this.httpHelper.request<Player>(`${this.endpoint}/${playerData.id}`,{
      method:'DELETE'
    })
  }

  getStoreFrontData(playerData: Player): Observable<Player[]> {
    return this.httpHelper.request<Player[]>(
      `${this.endpoint}/?userId=${playerData.id}`,
      { method: 'GET' }
    );
  }

  getPlayerDocument(): Observable<PlayerDocument[]> {
    return this.httpHelper.request<PlayerDocument[]>('players/required_documents/');
  }

  markDeleted(player: Player): Observable<null> {
    return this.httpHelper.request(`${this.endpoint}/${player.id}/mark_deleted`, { method: "DELETE" });
  }

  getVideos(): Observable<PlayerVideoData> {
    return this.httpHelper.request<PlayerVideoData>(`${this.endpoint}/videos`);
  }

  journalEntires(startDate: Date, endDate: Date): Observable<JournalEntrySale[]> {
    let search = new HttpParams({
      fromObject: {
        'start_date': startDate.toISOString().split('T')[0],
        'end_date': endDate.toISOString().split('T')[0]
      }
    })
    return this.httpHelper.request<JournalEntrySale[]>(`${this.endpoint}/journal_entries`, { method: "GET", search: search });
  }

  poolSales(): Observable<PoolLicenseSettings[]> {
    return this.httpHelper.request<PoolLicenseSettings[]>(`${this.endpoint}/pool_sales`, { method: "GET" });
  }

  playerOptOut(token: string, playerId: number): Observable<boolean> {
    return this.httpHelper.request<boolean>(`player_opt_out`, { body: {token, playerId}, method: "POST" })
  }


  completedDocument(playerId: number): Observable<PlayerDocument> {
    return this.httpHelper.request<PlayerDocument>(`${this.endpoint}/${playerId}/completed-document`, { method: "GET" });
  }

  signUps(): Observable<AthleteSignUpResponse> {
    return this.httpHelper.request<AthleteSignUpResponse>(`${this.endpoint}/sign-ups`, { method: "GET" });
  }
}
