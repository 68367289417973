import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from '../api/users.service';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersStateService {
  usersService = inject(UsersService);

  private readonly _usersSource = new BehaviorSubject<User[]>(null);
  public readonly users$ = this._usersSource.asObservable();

  public init(): void {
    if (!this._usersSource.getValue()) {
      this.usersService.getAll().subscribe(users => {
        this._usersSource.next(users);
      })
    }
  }

  public initCommissions(): void {
    this.usersService.filter({hasCommissions: true}).subscribe(users => {
      this._usersSource.next(users);
    })
  }

  getBlanks(): User[] {
    return this._usersSource.getValue();
  }

}


