import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { MarshallBin, MarshallBinCreate } from '../../models/workstations';
import { Order, OrderAuditLog, OrderPart, OrderPartItem, OrderStatus, ShippingLabel } from '../../models/prints';
import { BlankSkuFull, InventoryItem } from '../../models/blank';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private endpoint: string = 'orders';

  constructor(private httpHelper: HttpHelperService) { }

  getAll(): Observable<Order[]> {
    return this.httpHelper.request<Order[]>(this.endpoint);
  }

  search(search: string): Observable<Order[]> {
    return this.httpHelper.request<Order[]>(this.endpoint, {search: {search}, method: 'GET'});
  }

  get(id: number): Observable<Order> {
    return this.httpHelper.request<Order>(`${this.endpoint}/${id}`);
  }

  getOpen(): Observable<Order[]> {
    return this.httpHelper.request<Order[]>(`${this.endpoint}/open`);
  }

  create(item: Order): Observable<Order> {
    return this.httpHelper.request<Order>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: Order): Observable<Order> {
    return this.httpHelper.request<Order>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  delete(id: number): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/${id}`, { method: 'DELETE' });
  }

  shipItems(id: number, orderPartItemIds: number[]): Observable<OrderPartItem[]> {
    return this.httpHelper.request<OrderPartItem[]>(`${this.endpoint}/${id}/partial_ship_items`, { body: { orderPartItemIds }, method: 'POST' });
  }

  createShippingLabel(id: number, orderPartItemIds: number[]): Observable<ShippingLabel> {
    return this.httpHelper.request<ShippingLabel>(`${this.endpoint}/${id}/create_shipping_label`, { body: { orderPartItemIds }, method: 'POST' });
  }

  getShippingRate(id: number): Observable<number> {
    return this.httpHelper.request<number>(`${this.endpoint}/${id}/shipping_rate`, { method: 'GET' });
  }

  getJobs(status: string): Observable<OrderPartItem[]> {
    return this.httpHelper.request<OrderPartItem[]>(`${this.endpoint}/jobs`, { search: { status }, method: 'GET' });
  }

  dispatchItems(orderPartItemIds: number[]): Observable<OrderPartItem[]> {
    return this.httpHelper.request<OrderPartItem[]>(`${this.endpoint}/dispatch_jobs`, { body: { orderPartItemIds }, method: 'POST' });
  }

  revertOpiStatus(orderPartItemId: number, status: OrderStatus): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/revert_opi_status`, { body: { orderPartItemId, status }, method: 'POST' });
  }

  updateOpiStatuses(orderPartItemIds: number[], status: OrderStatus): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/update_opi_statuses`, { body: { orderPartItemIds, status }, method: 'POST' });
  }

  checkPrintForm(referenceId: string): Observable<number> {
    return this.httpHelper.request<number>(`${this.endpoint}/check_print_form`, { body: { referenceId }, method: 'POST' });
  }

  outOfStock(): Observable<InventoryItem[]> {
    return this.httpHelper.request<InventoryItem[]>(`${this.endpoint}/out_of_stock`, { method: 'GET' });
  }

  outOfStockExport(): Observable<Blob> {
    return this.httpHelper.request<Blob>(`${this.endpoint}/out_of_stock`, { method: 'GET', search: {export: true}, responseType: 'blob' });
  }

  pickBlank(orderPartItemId): Observable<BlankSkuFull> {
    return this.httpHelper.request<BlankSkuFull>(`${this.endpoint}/pick_blank`, { body: { orderPartItemId },  method: 'POST' });
  }

  addAuditMessage(orderId: number, message: string): Observable<OrderAuditLog> {
    return this.httpHelper.request<OrderAuditLog>(`${this.endpoint}/${orderId}/add-audit`, { body: { message }, method: 'POST' });
  }

}
