import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LastPayout, Payouts, PayoutsFilter, PayoutsPaginatedData, Total, TotalDue, TriggerPayout } from '../../models/payouts';
import { HttpHelperService } from '../http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserPayoutsService {
  totalDue$: TotalDue = null;
  total$: Total = null;
  lastPayoutsDetails$:LastPayout[]=null;
  private endpoint: string = 'payouts';

  constructor(private httpHelper: HttpHelperService) { }

  resetState() {
    this.total$ = this.totalDue$ = null;
    this.lastPayoutsDetails$=null;
  }

  Total(): Observable<Total> {
    return this.httpHelper.request<Total>(`${this.endpoint}/total`, { method: 'GET' });
  }
  TotalDue(): Observable<TotalDue> {
    return this.httpHelper.request<TotalDue>(`${this.endpoint}/total_due`, { method: 'GET' });
  }

  getPayouts(filter? : PayoutsFilter): Observable<PayoutsPaginatedData> {
    return this.httpHelper.request<PayoutsPaginatedData>(`${this.endpoint}`, { method: 'GET', search: filter });
  }

  getAllPayouts(filter? : PayoutsFilter): Observable<Payouts[]> {
    return this.httpHelper.request<Payouts[]>(`${this.endpoint}`, { method: 'GET', search: filter });
  }

  get(id: number): Observable<Payouts> {
    let search = new HttpParams({
      fromObject: {
        players: true
      }
    })
    return this.httpHelper.request<Payouts>(`${this.endpoint}/${id}`, { search });
  }

  triggerPayout(data: TriggerPayout): Observable<Payouts> {
    return this.httpHelper.request<Payouts>(`${this.endpoint}/trigger_payout`, { method: 'POST', body: data });
  }

  lastPayout():Observable<LastPayout[]> {
    return this.httpHelper.request<LastPayout[]>(`payout-methods`, { method: 'GET' });
  }

  deletePayout(id:number){
    return this.httpHelper.request<void>(`payout-methods/${id}`, { method: 'DELETE' });
  }

  updatePayoutStatus(payout: Payouts, status: string): Observable<Payouts> {
    return this.httpHelper.request<Payouts>(`${this.endpoint}/${payout.id}/update_status`, { method: 'POST', body: {status} });
  }

}
