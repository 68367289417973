import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { Route } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { CoreModule } from 'src/app/core/core.module';
import { UserPayoutsComponent } from './user-payouts/user-payouts.component';
import { PayoutsPaymentComponent } from './user-payouts/payouts-payment/payouts-payment.component';
import { PayoutDisclosureComponent } from './user-payouts/payout-disclosure/payout-disclosure.component';
import { ProfilePicUploadComponent } from './user-settings/profile-pic-upload/profile-pic-upload.component';
import { WidgetLargeChartModule } from 'src/@vex/components/widgets/widget-large-chart/widget-large-chart.module';
import { WidgetLargeGoalChartModule } from 'src/@vex/components/widgets/widget-large-goal-chart/widget-large-goal-chart.module';
import { WidgetAssistantModule } from 'src/@vex/components/widgets/widget-assistant/widget-assistant.module';
import { WidgetQuickLineChartModule } from 'src/@vex/components/widgets/widget-quick-line-chart/widget-quick-line-chart.module';
import { WidgetQuickValueCenterModule } from 'src/@vex/components/widgets/widget-quick-value-center/widget-quick-value-center.module';
import { WidgetQuickValueStartModule } from 'src/@vex/components/widgets/widget-quick-value-start/widget-quick-value-start.module';
import { WidgetTableModule } from 'src/@vex/components/widgets/widget-table/widget-table.module';
import { PaypalPayoutsAacComponent } from './user-payouts/paypal-payouts-aac/paypal-payouts-aac.component';
import { PayoutsGuard } from 'src/app/core/guards/payouts.guard';

export const USER_ROUTES: Route[] = [
  {
    path: '',
    redirectTo: 'settings',
    pathMatch: 'full'
  },
  {
    data: { label: 'Settings' },
    path: 'settings',
    component: UserSettingsComponent,
  },
  {
    data: { label: 'Payouts' },
    path: 'payouts',
    component: UserPayoutsComponent,
    canActivate: [PayoutsGuard]
  },


]

@NgModule({
  declarations: [
    UserSettingsComponent,
    UserPayoutsComponent,
    PayoutsPaymentComponent,
    PayoutDisclosureComponent,
    ProfilePicUploadComponent,
    PaypalPayoutsAacComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    PageLayoutModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    WidgetLargeChartModule,
    WidgetLargeGoalChartModule,
    WidgetQuickLineChartModule,
    WidgetQuickValueCenterModule,
    WidgetQuickValueStartModule,
    WidgetAssistantModule,
    WidgetTableModule,
  ]
})
export class UserModule { }
