
<div class="flex flex-col gap-2 p-4 items-center" *ngIf="school && design">
  <div *ngIf="design.originalUploadBack || design.template.multiSide else frontImages">
    <mat-tab-group >
      <mat-tab label="Front">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5">
          <div [blockLoader]="loadingDesigns.includes('whiteColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For White Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image"  [src]="design?.whiteColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.whiteColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('darkColorway')" class="relative min-w-[200px]  min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10 bg-black">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Dark Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image"  [src]="design?.darkColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.darkColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('lightColorway')" class="relative min-w-[200px]  min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10 bg-gray-300">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Light Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image" [src]="design?.lightColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.lightColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('primaryColorway')" class="relative min-w-[200px]  min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10" [ngStyle]="{'background-color': school.primaryHex}">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Primary Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image" [src]="design?.primaryColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.primaryColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('secondaryColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10" [ngStyle]="{'background-color': school.secondaryHex}">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Secondary Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image" [src]="design?.secondaryColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.secondaryColorway"/>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Back">
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5">
          <div [blockLoader]="loadingDesigns.includes('whiteBackColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For White Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image"  [src]="design?.whiteBackColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.whiteBackColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('darkBackColorway')"  class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10 bg-black">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Dark Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image"  [src]="design?.darkBackColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.darkBackColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('lightBackColorway')"  class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10 bg-gray-300">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Light Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image" [src]="design?.lightBackColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.lightBackColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('primaryBackColorway')"  class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10" [ngStyle]="{'background-color': school.primaryHex}">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Primary Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image" [src]="design?.primaryBackColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.primaryBackColorway"/>
          </div>
          <div [blockLoader]="loadingDesigns.includes('secondaryBackColorway')"  class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10" [ngStyle]="{'background-color': school.secondaryHex}">
            <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Secondary Products</label>
            <img class="w-48 h-48 object-contain"  alt="Upload Image" [src]="design?.secondaryBackColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.secondaryBackColorway"/>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <mat-button-toggle-group [(ngModel)]="design.licensingStatus" (change)="updateLicensingStatus($event)">
    <mat-button-toggle [value]="status" *ngFor="let status of LICENSING_STATUSES">{{status}}</mat-button-toggle>
  </mat-button-toggle-group>
  <p *ngIf="design.lastLicenseAction" class="text-lg font-semibold">
    Last Updated: {{design.lastLicenseAction| date: 'medium'}}
  </p>
  <ng-template #frontImages>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5">
      <div [blockLoader]="loadingDesigns.includes('whiteColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10">
        <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For White Products</label>
        <img class="w-48 h-48 object-contain" alt="Upload Image"  [src]="design?.whiteColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.whiteColorway"/>
      </div>
      <div [blockLoader]="loadingDesigns.includes('darkColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10 bg-black">
        <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Dark Products</label>
        <img class="w-48 h-48 object-contain" alt="Upload Image"  [src]="design?.darkColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.darkColorway"/>
      </div>
      <div [blockLoader]="loadingDesigns.includes('lightColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10 bg-gray-300">
        <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Light Products</label>
        <img class="w-48 h-48 object-contain" alt="Upload Image" [src]="design?.lightColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.lightColorway"/>
      </div>
      <div [blockLoader]="loadingDesigns.includes('primaryColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10" [ngStyle]="{'background-color': school.primaryHex}">
        <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Primary Products</label>
        <img class="w-48 h-48 object-contain" alt="Upload Image" [src]="design?.primaryColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.primaryColorway"/>
      </div>
      <div [blockLoader]="loadingDesigns.includes('secondaryColorway')" class="relative min-w-[200px] min-h-[200px] flex flex-col items-center justify-center rounded border-gray-400 border p-3 pt-10" [ngStyle]="{'background-color': school.secondaryHex}">
        <label class="absolute rounded-t p-0.5 text-white bg-primary top-0 w-full text-center font-semibold">For Secondary Products</label>
        <img class="w-48 h-48 object-contain" alt="Upload Image" [src]="design?.secondaryColorway" (error)="commonService.imageErrorHandle($event)" *ngIf="design?.secondaryColorway"/>
      </div>
    </div>
  </ng-template>


</div>
<div class="flex flex-row-reverse w-full">
  <button type="button" (click)="generateColorways()" [buttonLoader]="loadingDesigns.length > 0"
    class="mt-4 items-center text-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary/50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
  >Generate Designs</button>
</div>