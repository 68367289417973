import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BlankSku, BlankSkuFilter, BlankSkuFull, BlankSkuPaginate } from '../../models/blank';
import { HttpHelperService } from '../http-helper.service';
import { PaginatedData, PaginatedRequest } from '../../models/utils';

export type LiveInventoryItem = {
  id: number;
  blankColor: string;
  sizeCode: string;
  model: string;
  manufacturer: string;
  description: string;
  inventory: number;
  backOrdered: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BlankSkuService {
  blankSkuPaginateList$ : BlankSkuPaginate=null;
  private endpoint: string = 'blank-sku';

  constructor(private httpHelper: HttpHelperService) { }
  resetState(){
    this.blankSkuPaginateList$=null;
  }

  getAll(): Observable<BlankSku[]> {
    return this.httpHelper.request<BlankSku[]>(this.endpoint);
  }

  getAllByFilter(filter?:BlankSkuFilter): Observable<BlankSkuPaginate> {
    return this.httpHelper.request<BlankSkuPaginate>(this.endpoint,{search:filter});
  }

  get(id: number): Observable<BlankSkuFull> {
    return this.httpHelper.request<BlankSkuFull>(`${this.endpoint}/${id}`);
  }

  create(item: BlankSku): Observable<BlankSku> {
    return this.httpHelper.request<BlankSku>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: BlankSku): Observable<BlankSku> {
    return this.httpHelper.request<BlankSku>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  inventory(page: PaginatedRequest<Partial<BlankSku>>): Observable<PaginatedData<LiveInventoryItem>> {
    return this.httpHelper.request<PaginatedData<LiveInventoryItem>>('live-inventory', { search: page });
  }

  oosUpdate(): Observable<{off: LiveInventoryItem[], on: LiveInventoryItem[]}> {
    return this.httpHelper.request<{off: LiveInventoryItem[], on: LiveInventoryItem[]}>(`${this.endpoint}/oos-update`);
  }

  runOosUpdate(on: number[], off: number[]): Observable<Blob> {
    return this.httpHelper.request<Blob>(`${this.endpoint}/sync-live-inventory`, { body: { on, off }, method: 'POST', responseType: 'blob' });
  }
}
