import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/api/user.service';
import { Location } from '@angular/common';
import { AuthService } from '../services/api/auth.service';
import { NotifierService } from '../services/notifier.services';

@Injectable({
  providedIn: 'root'
})
export class UserGuard  {
  constructor(private userService: UserService,
    private location: Location,
    private notifier: NotifierService,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.currentUser.group === "ORG" && this.location.path() === "/user/payouts") {
      this.authService.logout();
      this.notifier.alert({
        message: 'Permission Denied Login Again !',
        type: 'error',
        duration: 3000,
      });
      return false
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.userService.currentUser.group === "ORG" && this.location.path() === "/user/payouts") {
      this.authService.logout();
      this.notifier.alert({
        message: 'Permission Denied Login Again !',
        type: 'error',
        duration: 3000,
      });
      return false
    }
    return true;
  }
}
