<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon>menu</mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async" [routerLink]="['/']" class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/logo.svg" />
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">VEX</h1>
  </a>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async" [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>
  <div class="items-center hidden sm:inline-flex">
    <ng-container *ngFor="let crumb of breadcrumbs; let i=index;">
        <a [routerLink]="crumb.link" class="hover:text-primary" [ngClass]="i == (breadcrumbs.length-1)? 'text-primary' : 'text-gray'">{{ crumb.name }}</a>
        <div class="text-gray p-2" *ngIf="i < (breadcrumbs.length-1)">/</div>
    </ng-container>
  </div>
  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div class="px-1"  *ngIf="userService.isAdmin() && accessExist">
      <span  class="dark-white-bg  text-gray-900 dark:text-white rounded px-2 py-1 font-semibold text-xs flex-none bg-emerald-300" *ngIf="school$ | async as school">
        {{ school.fullName }} - ID: {{ school.id }}
      </span>
    </div>
    <div class="px-1">
      <a *ngIf="accessExist" mat-raised-button type="button" (click)="switchAccess()" [href]="userGroup + '/home'">
        <mat-icon color="primary">switch_access_shortcut</mat-icon>
        {{this.userService?.currentUser?.accessBy==='ADMIN'?'Switch To Admin':'Switch To ORG'}}
      </a>
    </div>

    <!-- <div (click)="isDark ? disableDarkMode() : enableDarkMode()" *ngrxLet="isDark$; let isDark"
      class="rounded-full flex items-center cursor-pointer relative text-contrast-dark select-none"
      matRipple>
      <div class="m-2 h-6 w-6 rounded-full">
        <mat-icon color="primary" *ngIf="isDark">dark_mode</mat-icon>
        <mat-icon color="primary" *ngIf="!isDark">light_mode</mat-icon>
      </div>
    </div> -->

    <div class="px-1">
      <button mat-icon-button type="button" (click)="openHelpCenter()">
        <mat-icon color="primary">help</mat-icon>
      </button>
    </div>

    <div class="px-1">
      <vex-toolbar-notifications></vex-toolbar-notifications>
    </div>


    <div *ngIf="userVisible$ | async" class="px-1">
      <vex-toolbar-user></vex-toolbar-user>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async" [class.hidden]="mobileQuery">
</vex-navigation>
