<div class="card overflow-auto">
  <div class="bg-app-bar whole-change-bg bg-white-td px-6 h-16 border-b sticky left-0 flex items-center" [buttonLoader]="loader">
    <h2 class="title black my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()" class="tableTitle">Stores</span>
      <span *ngIf="selection.hasValue()" class="tableTitle">{{ selection.selected.length }}
        Stores<span *ngIf="selection.selected.length > 1">s</span> selected</span>
    </h2>

    <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
      <button color="primary" mat-icon-button matTooltip="Load School Dashbaord" type="button">
        <mat-icon>settings</mat-icon>
      </button>
    </div>

    <div
      class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border-gray-300">
      <mat-icon class="icon-sm text-secondary">search</mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        placeholder="Search..." type="search">
    </div>

    <span class="flex-1"></span>

    <button *ngIf="this.userService?.currentUser?.group==='ADMIN'" (click)="createSchool()" class="ml-4 flex-none" color="primary" mat-mini-fab
      matTooltip="Add School" type="button">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <table @stagger [dataSource]="dataSource" class="w-full h-full" mat-table matSort>

    <!-- Image Column -->
    <ng-container matColumnDef="mainLogo">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
        <img [src]="row['mainLogo']" (error)="commonService.imageErrorHandle($event)" class="avatar h-8 w-8 align-middle">
      </td>
    </ng-container>

    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
      </ng-container>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actions" >
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
        <button *ngIf="!userService.isArtwork()" (click)="$event.stopPropagation()" [matMenuTriggerData]="{ school: row }"
          [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button" [class.spinner]="dataLoading">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr (click)="updateSchool(row)" *matRowDef="let row; columns: visibleColumns;" @fadeInUp
      class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="visibleColumns.length" class="noDataFound">
        <span *ngIf="!loader">No Data Found</span>
      </td>
    </tr>
  </table>

  <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" class="sticky left-0"></mat-paginator>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
    class="checkbox-item mat-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-school="school" matMenuContent>
    <a (click)="signinSchool(school)" href="school/home" mat-menu-item>
      <mat-icon>login</mat-icon>
      <span>Sign in</span>
    </a>
    <button (click)="updateSchool(school)" mat-menu-item>
      <mat-icon>edit</mat-icon>
      <span>Modify</span>
    </button>
    <button (click)="getSignupLink(school, 'user-signup-form')" mat-menu-item  *ngIf="userService.isAdmin() || userService.isReadOnly()">
      <mat-icon>link</mat-icon>
      <span>Get Sign-Up Link</span>
    </button>
    <button (click)="getSignupLink(school, 'user-opt-out')" mat-menu-item  *ngIf="userService.isAdmin() || userService.isReadOnly()">
      <mat-icon>link</mat-icon>
      <span>Get Opt out Link</span>
    </button>
    <a [routerLink]="['/admin/schools/', school.id]" mat-menu-item *ngIf="userService.isAdmin() || userService.isReadOnly()">
      <mat-icon>settings</mat-icon>
      <span>Details</span>
    </a>
    <button *ngIf="this.userService?.currentUser?.group==='ADMIN'" (click)="deleteSchool(school,'deleteSchool')" mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span>Delete School</span>
    </button>
    <button *ngIf="this.userService?.currentUser?.group==='ORG'" (click)="unassignSchool(school,'unassignSchool')" mat-menu-item>
      <mat-icon>close</mat-icon>
      <span>Unassign Store</span>
    </button>
  </ng-template>
</mat-menu>
