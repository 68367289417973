<vex-page-layout>
  <vex-page-layout-content class="container">
    <div class="user-payout-main-top">
      <div class="have-school" *ngIf="!(this.usersService?.userType != 'SCHOOL')">
        <div class="total-status-of-sale td-padding-first-0 td-row container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">

          <!-- <div class="card card-td-outer p-6 relative h-full flex flex-col justify-center full hover:shadow-lg text-primary trans-shadow user-one-">
            <div class="sm:col-span-2">
              <vex-widget-large-chart [series]="userPayoutSeries" title="User Payouts" class="sm:col-span-2">
              </vex-widget-large-chart>
            </div>
          </div> -->
          <div class="card card-td-outer p-6 relative h-full flex flex-col justify-center full hover:shadow-lg text-primary trans-shadow user-one-">

            <div class="grid grid-cols-1 px-0 sm:grid-cols-1 md:grid-cols-1">
              <div [buttonLoader]="totalLoader"
                class="card p-6 relative h-full flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-one">
                <ng-container *ngIf="!totalLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Total Payouts
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                    <h2 class="font27 font-bold color-dark"> {{this.totalData?.total || 0 | currency}}</h2>
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon> attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div [buttonLoader]="totalDueLoader"
                class="card p-6 relative h-full flex flex-col td-col-card-full  justify-center full hover:shadow-lg trans-shadow user-two">
                <ng-container *ngIf="!totalDueLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Current Available To
                    Payout
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                    <h2 class="font27 font-bold color-dark">{{this.totalDueData?.totalDue || 0 | currency}}</h2>
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon> attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="have-no-school" *ngIf="this.usersService?.userType != 'SCHOOL'">
        <div class="total-status-of-sale td-padding-0 td-row container grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-1">
          <!-- <div class="card card-td-outer p-6 relative h-full flex flex-col justify-center full hover:shadow-lg text-primary trans-shadow user-one-">
            <div class="sm:col-span-2">
              <vex-widget-large-chart [series]="userPayoutSeries" title="User Payouts" class="sm:col-span-2">
              </vex-widget-large-chart>
            </div>

          </div> -->
          <div class="card card-td-outer relative h-full flex- flex-col justify-center full hover:shadow-lg text-primary  trans-shadow user-two-">
            <div class="total-status-of-sale td-row container grid grid-cols-1 px-0 sm:grid-cols-1 md:grid-cols-1">
              <div [buttonLoader]="totalLoader"
                class="card p-6 relative h-full flex flex-col td-col-card-full justify-center full hover:shadow-lg text-primary trans-shadow user-one">
                <ng-container *ngIf="!totalLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Total Payouts
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                    <h2 class="font27 font-bold color-dark"> {{this.totalData?.total || 0 | currency}}</h2>
                    <div class="rounded-full w-12 h-12 flex items-center justify-center text-primary bg-primary/10">
                      <mat-icon> attach_money</mat-icon>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div [buttonLoader]="totalDueLoader"
                class="card p-6 relative h-full flex flex-col td-col-card-full justify-center full hover:shadow-lg text-primary  trans-shadow user-two">
                <ng-container *ngIf="!totalDueLoader">
                  <h3 class="text-base md:text-lg text-center md:text-left font-thin m-0 color-dark">Current Available To
                    Payout
                  </h3>
                  <div class="flex items-center flex-col md:flex-row justify-between  mt-auto">
                    <h2 class="font27 font-bold color-dark">{{this.totalDueData?.totalDue || 0 | currency}}</h2>
                    <div class="rounded-full h-14 flex items-center justify-center text-primary mr-2">
                      <div class="title col-item justify-center td-col-card-full relative trans-shadow flex flex-col items-center cursor-pointer"
                        [buttonLoader]="totalDueLoader">
                        <ng-container *ngIf="!totalDueLoader">
                          <div [matTooltipPosition]="'above'" [matTooltipShowDelay]="300" [matTooltipHideDelay]="1000"
                            matTooltip="Your available Payout must be greater than or equal to $5.00"
                            [matTooltipDisabled]="!(this.totalDueData?.totalDue === null ||this.totalDueData?.totalDue<5)">
                            <button color="primary" mat-raised-button color="primary" (click)="showPayout()"
                              [disabled]="this.totalDueData?.totalDue === null ||this.totalDueData?.totalDue<5">Get Payout</button>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-payout-table (sendGraphData)="getGraphData($event)" [refresh]="refreshPayoutList" [payoutsDisplayedColumns]="payoutsDisplayedColumns" class="mt-4 block"></app-payout-table>
    <br>

  </vex-page-layout-content>
</vex-page-layout>
